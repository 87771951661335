@tailwind components;
@tailwind utilities;

:root {
  --color-secondary: #977f00;
  --color-primary: #e7aa0c;
}

@layer utilities {
  .text-2xs {
    font-size: 0.6rem;
  }
  .bg-gradient {
    background-image: linear-gradient(
      to right,
      var(--color-primary),
      var(--color-secondary)
    );
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.RaDatagrid-expandedPanel td {
  padding: 0;
}

.tox-promotion {
  display: none;
}

.tox-tinymce {
  border-radius: 0 !important;
}

.tox-tinymce-label {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  margin-bottom: 0.2rem;
}

main .MuiDrawer-root {
  background: #fff;
  border-right: 1px solid #ececec;
}

.RaSimpleList-tertiary {
  opacity: 1 !important;
}

main .RaSidebar-fixed {
  height: 100vh !important;
  background: #fff;
}
